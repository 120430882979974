.backLink {
    text-decoration: none;
}

.heading {
    font-weight: bold;
}

@media (min-width: 768px) {
    .configuratorHeader {
        position: fixed;
        top: 40px;
        left: 40px;
    }
}

.backButton {
    display: inline-block;
    background: #fff;
    padding: 10px;
    height: 50px;
    width: 50px;
    text-align: center;
    color: #505050;
}

.result {
    margin-top: 2rem;
    background: #fff;

/*    page-break-before: always; */
}

.columnHeight {
    text-align: center;
}

.productSpecsItem {
    font-size: 14px;
    margin-right: 10px;
    padding-right: 10px;
    white-space: nowrap;
    display: inline-block;
}


@media print {
    .result table {
         page-break-inside: avoid;
    }

    .resultWrapper > div,
    .resultWrapper > div > div {
        display: block;
        flex: none;
    }
}

.result table td {
    vertical-align: middle;
}

.techChangesNote {
    font-size: 14px;
    color: #505050;
    padding: 15px;
}
