.canvas {
    position: absolute;
    width: 500px;
    height: 100%;
    bottom: 0;
    top: 50px;
}

.canvasPlaceholder {
    display: none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.canvasPlaceholderCE180 {
    display: block;
    left: 200px;
}

.canvasPlaceholderCE180 img {
    width: 102px;
    height: auto;
}

.canvasPlaceholderCE200 {
    display: block;
    left: 184px;
}

.canvasPlaceholderCE200 img {
    width: 135px;
    height: auto;
}

.canvasPlaceholderCE230 {
    display: block;
    left: 183px;
}

.canvasPlaceholderCE230 img {
    width: 136px;
    height: auto;
}

.canvasImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    /* overflow: hidden; */
}

.canvasButtons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
}

.canvasMiniature {
    position: absolute;
    top: 0;
    left: 43px;
    right: 0;
    bottom: 0;
    transform: scale(0.1);
    transform-origin: left top;
}

.entry {
    position: relative;
}

.position {

}

.position img {
    display: block;
    margin: 0 auto;
    width: 500px;
    height: auto;
}

.positionG {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.positionGImageWrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    overflow: hidden;
}

.positionG img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.positionGAddButton {

}

.positionA {
    position: relative;
    z-index: 120;
    min-height: 73px;
}

.positionAAddButton {
    position: absolute;
    z-index: 200;
    top: 40px;
    left: 0;
    right: 0;
}

.positionZ {
    position: relative;
    z-index: 110;
}

.positionZAddButton {
    position: relative;
    margin-top: 30px;
}

.positionACC {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 130;
}

.positionACC img {
    display: block;
    margin: 0 auto;
    width: 500px;
    height: auto;
}

.dynamicPosition {
    position: relative;
    overflow: hidden;
}

.dynamicPosition img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
