.modalWrap {
    height: auto;
    margin: 0 auto;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 50%;
    bottom: 0;
    padding: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.modal {
    position: relative;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    background: #fff;
    color: #505050;
    max-height: 100%;
    box-shadow: 0 0 40px rgba(0,0,0,0.5);
}

.modalHeader {
    position: relative;
    flex: 1 0;
    padding: 20px;
}

.modalHeader h2 {
    margin-bottom: 0;
}

.modalContent {
    position: relative;
    overflow-y: scroll;
    flex: 1 1;
    padding: 0 20px;
    min-height: 100px;
}

.modalFooter {
    position: relative;
    flex: 1 0;
    background: #eee;
    border-top: 2px solid #e22018;
}

.modalCloseButton {
    position: absolute;
    top: 25px;
    right: 25px;
}

.productItem {
    display: flex;
    border-bottom: 1px solid #ccc;
    align-items: center;
}

.productItem:last-child {
    border: none;
}

.productItemButton {
    flex: 1 0;
    margin-right: -40px;
    margin-left: 20px;
    z-index: 10;
}

.productItemImage {
    flex: 1 0;
    padding: 10px;
}

.productItemName {
    flex: 100;
    padding: 20px 20px 20px 0;
}

.button {
    display: inline-block;
    background: #e22018;
    color: #fff;
    cursor: pointer;
    height: 30px;
    width: 30px;
    text-align: center;
}

.button:hover {
    background: #FF514A;
}
