.item {
    display: inline-block;
    margin-right: .3rem;
    position: relative;
}

.item input {
    display: none;
}

.item label {
    display: inline-block;
    cursor: pointer;
    border: 2px solid #ddd;
    color: #505050;
    font-weight: 400;
    text-align: center;
    vertical-align: baseline;
    background-color: transparent;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.2;
    border-radius: 0;
    position: relative;
}

.item img {
    width: 120px;
    height: 120px;
}

.item span {
    display: none;
    position: absolute;
    z-index: 10;
    bottom: 5px;
    left: 5px;
    background: #505050;
    color: #fff;
    padding: .3rem;
    font-size: 14px;
}

.item:hover span, .item:focus span {
    display: block;
}

.item input:hover + label {
    border-color: #e22018;
    color: #e22018;
}

.item input:checked + label {
    border-color: #e22018;
    background-color: #e22018;
    color: #fff;
}

.item input:disabled + label {
    opacity: 0.3;
}
