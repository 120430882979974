.removeButton {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    margin-left: 40px;
    margin-top: -15px;
    width: 300px;
}

.removeButtonToggle {
    display: inline-block;
    background: #fff;
    padding: 0px;
    height: 30px;
    width: 30px;
    text-align: center;
    color: #fff;
    background: #e22018;
    border: none;
    outline: none !important;
}

.removeButtonToggle:hover {
    background: #FF514A;
}
