.backLink {
    text-decoration: none;
}

.heading {
    font-weight: bold;
}

.settingsView {
    margin: 0 auto;
    max-width: 700px;
    padding: 30px;
    background: #fff;
    color: #505050;
}
