.canvasOrientation {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    text-align: center;
}

.canvasFront {
    position: absolute;
    width: 500px;
    height: 100%;
    bottom: 0;
    top: 50px;
    left: -200px;
}

.canvasBack {
    position: absolute;
    width: 500px;
    height: 100%;
    bottom: 0;
    top: 50px;
    left: 200px;
}

.canvasFrontClose {
    left: -100px;
}

.canvasBackClose {
    left: 100px;
}

.canvasImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow: hidden;
}

.canvasButtons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
}

.canvasMiniature {
    position: absolute;
    top: 0;
    left: 43px;
    right: 0;
    bottom: 0;
    transform: scale(0.1);
    transform-origin: left top;
}

.position {

}

.positionS {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.positionA {
    position: relative;
    z-index: 10;
    height: 324px;
}

.positionFB {
    position: relative;
    height: 154px;
}

.positionACC {
    position: absolute;
    left: 0;
    right: 0;
}

.positionACC img {
    display: block;
    margin: 0 auto;
    width: 500px;
    height: auto;
}

.canvasImage .positionS { z-index: 110; }
.canvasImage .positionA { z-index: 100; }
.canvasImage .positionF1B1 { z-index: 119; }
.canvasImage .positionF2B2 { z-index: 118; }
.canvasImage .positionF3B3 { z-index: 117; }
.canvasImage .positionF4B4 { z-index: 116; }
.canvasImage .positionF5B5 { z-index: 115; }
.canvasImage .positionACC { z-index: 105; top: 0;}

.canvasButtons .positionS { z-index: 200; }
.canvasButtons .positionA { z-index: 210; }
.canvasButtons .positionF1B1 { z-index: 209; }
.canvasButtons .positionF2B2 { z-index: 208; }
.canvasButtons .positionF3B3 { z-index: 207; }
.canvasButtons .positionF4B4 { z-index: 206; }
.canvasButtons .positionF5B5 { z-index: 205; }
.canvasButtons .positionACC { z-index: 204; bottom: 200px; }
