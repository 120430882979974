.cityResultImage {
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
    left: 50%;
    margin-left: -125px;
    margin-bottom: -900px;
    width: 500px;
    height: 1926px; /* will be overriden by inline styles */
    transform: scale(0.5);
    transform-origin: left top;
}

@media print {
    .cityResultImage {
        /* transform: scale(0.25); */
    }
}
