.item {
    display: inline-block;
    margin-right: .3rem;
}

.item input {
    display: none;
}

.item label {
    display: inline-block;
    cursor: pointer;
    padding: .4rem .6rem;
    border: 2px solid #ddd;
    color: #505050;
    font-weight: 400;
    text-align: center;
    vertical-align: baseline;
    background-color: transparent;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.2;
    border-radius: 0;
    position: relative;
}

.item input:hover + label {
    border-color: #e22018;
    color: #e22018;
}

.item input:checked + label {
    border-color: #e22018;
    background-color: #e22018;
    color: #fff;
}

.item input:disabled + label {
    opacity: 0.3;
}
