.buttonPlaceholderWrap {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    width: 300px;
}

.buttonPlaceholder {
    display: inline-block;
    background: #fff;
    padding: 0px;
    height: 60px;
    width: 60px;
    text-align: center;
    color: #fff;
    background: #e22018;
    border: none;
    outline: none !important;
}
