
.accessoriesItem {
    display: flex;
    margin-top: 10px;
    font-size: 16px;
}

.accessoriesItemButton {
    flex: 1 0;
    z-index: 10;
    margin-right: 10px;
}

.accessoriesItemName {
    flex: 100;
    padding: 0;
}

.button {
    display: inline-block;
    background: #fff;
    color: #bbb;
    border: 2px solid #bbb;
    cursor: pointer;
    height: 30px;
    width: 30px;
    text-align: center;
}

.button:hover {
    color: #e22018;
    border-color: #e22018;
}

.buttonActive {
    background: #e22018;
    color: #fff !important;
    border-color: #e22018;
}

.itemHeading {
    font-weight: bold;
}

.itemSubline {
    font-size: 16px;
}
