
.image {
    position: fixed;
    z-index: 100;
    left: 20px;
    bottom: 42px;
    width: 140px;
}

.image > div {
    bottom: 0 !important;
    top: inherit !important;
    transform-origin: left bottom !important;
}
