.app {
    min-height: 100vh;
    background-color: #000;
    background-image: url('../assets/background.png');
    background-position: center top;
    /* background-attachment: fixed; */
    color: #fff;
}

.appLogo {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    width: 3rem;
}

.appLogo img {
    width: 100%;
    height: auto;
}

.appStatus {
    position: fixed;
    z-index: 1000;
    top: 30px;
    right: 80px;
    text-align: center;
    font-size: .8rem;
    padding: 20px;
    background: transparent;
}

.appLocale {
    position: absolute;
    z-index: 1000;
    top: 40px;
    right: 160px;
}

.appLocale a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    padding: 8px;
}

.appLocaleActive {
    color: #e22018 !important;
}
