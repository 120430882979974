.productHeading {
    font-weight: bold;
}

.productHeadingAccessory {
    font-size: 16px;
    font-weight: bold;
}

.productSubline {
    font-size: 16px;
}

.productSpecs {
    margin-top: 10px;
    font-size: 14px;
}

.productSpecsItem {
    margin-right: 10px;
    padding-right: 10px;
    white-space: nowrap;
    display: inline-block;
}

.productAccessories {
    margin-top: 20px;
}

.productAccessoriesHeading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.productNotice {
    font-size: 14px;
    margin-top: 10px;
    color: #e22018;
}
