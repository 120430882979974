.info {
    position: relative;
    margin: 0 10px;
    cursor: pointer;
}

.info .infoText {
    display: none;
    position: absolute;
    z-index: 10;
    top: -5px;
    left: 35px;
    background: #505050;
    color: #fff;
    padding: .3rem;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
    min-width: 250px;
    max-width: 300px;
}

.info:hover, .info:focus {
    color: #e22018;
}

.info:hover .infoText, .info:focus .infoText {
    display: block;
}
