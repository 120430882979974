.backLink {
    text-decoration: none;
}

.heading {
    font-weight: bold;
}

@media (min-width: 768px) {
    .configuratorHeader {
        position: fixed;
        z-index: 1000;
        top: 40px;
        left: 40px;
    }
}

.settingsButton {
    position: fixed;
    top: 160px;
    left: 40px;
    display: inline-block;
    background: #fff;
    padding: 10px;
    height: 50px;
    width: 50px;
    text-align: center;
    color: #505050;
}


.procedureInfo {
    display: none;
}

.procedureInfoVideo {
    margin-top: 20px;
    width: 60%;
}

@media (min-width: 768px) {
    .procedureInfo {
        display: block;
        position: fixed;
        top: 310px;
        left: 40px;
        max-width: 35%;
        font-size: .9rem;
    }
}

.canvasWrap {
    position: relative;
    width: 500px;
    height: 1926px; /* will be overriden by inline styles */
    margin: 50px auto;
}

@media (min-width: 768px) {
    .canvasWrap {
        margin-top: 160px;
    }
}

.canvasMiniature {
    position: absolute;
    top: 0;
    left: 43px;
    right: 0;
    bottom: 0;
    transform: scale(0.1);
    transform-origin: left top;
}

.canvasHeight {
    position: fixed;
    left: 180px;
    bottom: 35px;
    font-size: .8rem;
}

.resultsButton {
    position: fixed;
    z-index: 999;
    right: 20px;
    bottom: 10px;
}
