.backLink {
    text-decoration: none;
    color: #e22018;
    cursor: pointer;
}

.newView section {
    height: 100vh;
}

.newView img {
    height: 100vh;
    width: auto;
    max-width: 9999px;
}
