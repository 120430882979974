.sliderWrap {
    margin-right: 1px;
}

.slider {
    padding: 30px 0;
}

.sliderThumb {
    display: inline-block;
    cursor: move;
    padding: .4rem .6rem;
    margin-top: -20px;
    border: 2px solid #e22018;
    outline: none;
    color: #fff;
    font-weight: 400;
    text-align: center;
    vertical-align: baseline;
    background-color: #e22018;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.2;
    border-radius: 0;
    position: relative;
    white-space: nowrap;
}

.sliderTrack {
    margin-left: 2px;
    margin-right: 2px;
    border: 1px solid #e22018;
    position: relative;
}

.sliderTrack::before {
    display: block;
    content: " ";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #e22018;
    position: absolute;
    top: -10px;
    left: -2px;
}

.sliderTrack::after {
    display: block;
    content: " ";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #e22018;
    position: absolute;
    top: -10px;
    right: -2px;
}
