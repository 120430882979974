.toggleSelect {
    position: relative;
    display: inline-block;
    border-radius: 40px;
    border: 2px solid #ddd;
}

.toggleSelectTrue {
    border-color: #e22018;
    background: #e22018;
}

.toggleSwitch {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background: #ddd;
    border: 2px solid #ddd;
    border-radius: 40px;
    transition: 0.3s all;
}

.toggleSelectTrue .toggleSwitch {
    left: 30px;
    background: #fff;
    border-color: #e22018;
}

.item {
    display: inline-block;
    margin-right: 0;
}

.item input {
    display: none;
}

.item label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    display: inline-block;
    cursor: pointer;
    width: 60px;
    height: 30px;
    margin-bottom: 0;
    background-color: transparent;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 40px;
    position: relative;
    text-indent: -9999em;
}

.toggleSelectTrue .itemTrue {
    display: none;
}

.toggleSelectFalse .itemFalse {
    display: none;
}

.item input:hover + label {
    border-color: #e22018;
    color: #e22018;
}

.item input:checked + label {
    border-color: #e22018;
    background-color: #e22018;
    color: #fff;
}


.item input:disabled + label {
    opacity: 0.3;
}
