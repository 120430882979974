.appInset {
    padding: 40px 20px 40px 20px;
}

.containerFull {
    padding: 0 20px;
}

.containerFull .container {
    padding: 0 !important;
}
