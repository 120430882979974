.addButton {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    width: 300px;
}

.addButtonToggled {
    z-index: 40;
}

.addButtonToggle {
    display: inline-block;
    background: #fff;
    padding: 0px;
    height: 30px;
    width: 30px;
    text-align: center;
    color: #fff;
    background: #e22018;
    border: none;
    outline: none !important;
}

.addButtonToggle:hover {
    background: #FF514A;
}

.addButtonDropdown {
    position: absolute;
    top: 0;
    left: 40px;
}

.addButtonDropdown a {
    display: block;
    background: #fff;
    padding: 3px 10px;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
    color: #505050;
    font-size: .8rem;
}

.addButtonDropdown a:hover,
.addButtonDropdown a:focus {
    color: #e22018;
}
